<template>
  <div class="balsenaform">
    
    <DxDataGrid :data-source="allbalsena" :focused-row-enabled="true" key-expr="_id" @editing-start="getRowData($event)" @saved="onBalSenaSave($event)" @row-removed="onBalSenaDelete($event)" >
      <DxColumnChooser :enabled="true"/>

      <DxFilterRow :visible="true" />
      <DxHeaderFilter :visible="true" />
      <DxSearchPanel :visible="true" :width="240" placeholder="Search..." />
      <DxPaging :page-size="10"/>
      <DxPager
        :visible="true"
        :allowed-page-sizes="pageSizes"
        display-mode="compact"
        :show-page-size-selector="true"
        :show-info="true"
        :show-navigation-buttons="true"
      />
      <DxEditing :allow-updating="true" :allow-adding="true" :allow-deleting="true" mode="popup">
        <DxPopup :drag-enabled="true" :show-close-button="true" :show-title="true" title="Balsena Form Information"/>
        <DxForm>
          <DxDGItem :col-count="2" :col-span="2" item-type="group">
            <DxDGItem data-field="_id" />
            <DxDGItem data-field="RegNo" />
            <DxDGItem data-field="Name" />
            <DxDGItem data-field="MothersName" />
            <DxDGItem data-field="FathersName" />
            <DxDGItem data-field="SurName" />

            <DxDGItem data-field="Gender" />
            <DxDGItem data-field="DateOfBirth" />
            <DxDGItem data-field="WenlidoTraining" />
            <DxDGItem data-field="Phone" />
            <DxDGItem data-field="AttendingVanshalaBefore" />
            <DxDGItem data-field="AttendedCFVBefore" />
            <DxDGItem data-field="CurrentlyStudying" />
          </DxDGItem>
          <DxDGItem :col-count="2" :col-span="2" item-type="group" caption="Education Details">
            <DxDGItem data-field="Class" />
            <DxDGItem data-field="School" />
            <DxDGItem data-field="PrivateSchool" />
            <DxDGItem data-field="SchoolTimining" />
            <DxDGItem data-field="GoToTuition" />
          </DxDGItem>

          <DxDGItem :col-count="2" :col-span="2" item-type="group" :caption="lang.Working">
            <DxDGItem data-field="WorkingOutside" />
            <DxDGItem data-field="WorkWithFamily" />
            <DxDGItem data-field="ChildLabour" /> 
            <DxDGItem data-field="ParentsMigrate" />
          </DxDGItem>

          <DxDGItem :col-count="2" :col-span="2" item-type="group">
            <DxDGItem data-field="Married" />
            <DxDGItem data-field="CurrentlyEngagned" />
            <DxDGItem data-field="Addictions" /> 
            <DxDGItem data-field="Disabilities" /> 
            <DxDGItem data-field="CertificateOfDisability" />
            <DxDGItem data-field="SickleCell" />
            <DxDGItem data-field="Aneamic" />
          </DxDGItem>

          <DxDGItem :col-count="2" :col-span="2" item-type="group">
            <DxDGItem data-field="OrphanStayingWith" />
            <DxDGItem data-field="MotherAlive" />
            <DxDGItem data-field="FatherAlive" />
            <DxDGItem data-field="Facilitator">
              <DxLookup :data-source="volunteers" value-expr="_id" display-expr="name" />
            </DxDGItem>
            <DxDGItem data-field="Area">
              <DxLookup :data-source="areas" value-expr="_id" display-expr="name" />
            </DxDGItem>
            <DxDGItem data-field="Notes" />
          </DxDGItem>
        </DxForm>
      </DxEditing>
      <DxColumn data-field="_id" />
      <DxColumn :caption="lang.RegistrationNumber" data-field="RegNo" />
      <DxColumn :caption="lang.Name" data-field="Name" />
      <DxColumn :visible="false" :caption="lang.mother" data-field="MothersName" />
      <DxColumn :visible="false" :caption="lang.father" data-field="FathersName" />
      <DxColumn :visible="false" :caption="lang.SurName" data-field="SurName" />
      <DxColumn :caption="lang.Gender" data-field="Gender" edit-cell-template="dropDownGenders" />

      <DxColumn :caption=lang.DateOfBirth data-type="date" data-field="DateOfBirth" />
      <DxColumn :caption=lang.WenlidoTraining data-field="WenlidoTraining" />
      <DxColumn :caption=lang.Phone data-field="Phone" />
      <DxColumn :caption=lang.AttendingVanshalaBefore data-field="AttendingVanshalaBefore" />
      <DxColumn :caption=lang.AttendedCFVBefore data-field="AttendedCFVBefore" />
      <DxColumn :caption=lang.CurrentlyStudying data-field="CurrentlyStudying" data-type="boolean" />
      <DxColumn :caption=lang.Class data-field="Class" data-type="number" />
      <DxColumn :visible="false" :caption=lang.School data-field="School" />
      <DxColumn :visible="false" :caption="lang.private_or_govt" data-field="PrivateSchool" edit-cell-template="dropDownSchool" />

      <DxColumn :visible="false" :caption=lang.SchoolTiming data-field="SchoolTimining" />
      <DxColumn :visible="false" :caption=lang.GoToTuition data-field="GoToTuition" />
      <DxColumn :visible="false" :caption=lang.WorkingOutside data-field="WorkingOutside" data-type="boolean" />
      <DxColumn :visible="false" :caption=lang.child_work_in_their_farms data-field="WorkWithFamily" data-type="boolean" />
      <DxColumn :visible="false" :caption=lang.ChildLabour data-field="ChildLabour" /> 
      <DxColumn :visible="false" :caption=lang.engaged data-field="CurrentlyEngagned" data-type="boolean" />
      <DxColumn :visible="false" :caption=lang.married data-field="Married" data-type="boolean" />
      <DxColumn :visible="false" :caption=lang.Addictions data-field="Addictions" /> 
      <DxColumn :visible="false" :caption=lang.disabilities data-field="Disabilities" /> 
      <DxColumn :visible="false" :caption=lang.disability_certificate data-field="CertificateOfDisability" data-type="boolean" />
      <DxColumn :visible="false" :caption=lang.sicklecell data-field="SickleCell" data-type="boolean" />
      <DxColumn :visible="false" :caption=lang.aneamic data-field="Aneamic" data-type="boolean" />
      <DxColumn :visible="false" :caption=lang.child_stay_with data-field="OrphanStayingWith" />
      <DxColumn :visible="false" :caption="lang.mother +' '+ lang.alive" data-field="MotherAlive" data-type="boolean" />
      <DxColumn :visible="false" :caption="lang.father +' '+ lang.alive" data-field="FatherAlive" data-type="boolean" />
      <DxColumn :visible="false" :caption=lang.ParentsMigrate data-field="ParentsMigrate" />
      <DxColumn :caption="lang.Facilitator" data-field="Facilitator" cell-template="dropDownFacilitator" edit-cell-template="dropDownFacilitator" />
        
      <DxColumn :caption="lang.Area" data-field="Area" >
        <DxLookup :data-source="areas" value-expr="_id" display-expr="name" />
      </DxColumn>
      <DxColumn :visible="false" :caption=lang.Notes data-field="Notes" />

      <template #dropDownGenders="{ data }">
        <DxLookup :data-source="genders" v-model:value="data.value" value-expr="ID" display-expr="Name" />
      </template>
      <template #dropDownSchool="{ data }">
        <DxLookup :data-source="school_type" v-model:value="data.value" value-expr="ID" display-expr="Name" />
      </template>
      <template #dropDownFacilitator="{ data }">
        <DxLookup :data-source="volunteers" v-model:value="data.value" value-expr="_id" display-expr="name" />
      </template>
      <template #dropDownArea="{ data }">
        <DxLookup :data-source="areas" v-model:value="data.value" value-expr="_id" display-expr="name" />
      </template>
    </DxDataGrid>
  </div>
</template>

<script>
import axios from "axios";
import enjson from "../assets/locales/en.json"
import gjjson from "../assets/locales/gj.json"
import { confirm } from 'devextreme/ui/dialog';

export default {
  name: "BalSenaForm",
  async created()
  {
    this.storage = JSON.parse(localStorage.getItem("user"));
    if (this.storage.role == 0 || this.storage.role == 1)
    {
      alert("Not Authorized!");
      return;
    }
    if (this.storage.Language == "en")
      this.lang = enjson;
    else
      this.lang = gjjson;
    try
    {
      await axios.get(this.apiUrl + "/read/users", {
        headers: { "Authorization": "Bearer " + this.storage.token }
      }).then(async (result) => {
        this.volunteers = result.data;
      });
    }
    catch (error)
    {
      console.log(error);
      await axios.post(this.apiUrl + "/refresh", {}, {
        headers: { "Authorization": "Bearer " + this.storage.refresh_token }
      }).then(async (result) => {
        this.storage.token = result.data.token;
        this.storage.refresh_token = result.data.refresh_token;
        localStorage.setItem("user", JSON.stringify(this.storage));
        this.$forceUpdate();
        //this.updateComponent()
      });
    }
    await axios.get(this.apiUrl + "/read/Areas", {
      headers: { "Authorization": "Bearer " + this.storage.token }
    }).then(async (result) => {
      this.areas = result.data;
    });
    await axios.get(this.apiUrl + "/read/BalSena_Form", {
      headers: { "Authorization": "Bearer " + this.storage.token }
    }).then(async (result) => {
      this.allbalsena = result.data;
    });
  },
  methods:
  {
    getNextID()
    {
      if (this.allbalsena.length == 0) return 
      return 1+this.allbalsena.reduce((a,b) => Number(a._id) > Number(b._id) ? a._id : b._id)
    },
    getRowData(event){
      console.log(event)
    },
    async onBalSenaSave(event)
    {
      console.log(event)
      if (event.changes.length <= 0)
      {
        alert("No changes to be saved!!");
        return;
      }
      let indx = event.changes[0].data
      if (this.storage.role == 0 || this.storage.role == 1)
      {
        alert("Not Authorized!");
        return;
      }
      if (indx.RegNo == "")
      {
        alert("Registration Number Not Valid!");
        return;
      }
      if (indx.Facilitator == undefined || indx.Facilitator == '') {
        alert("Could not Save BalSena. Select a Facilitator!");
        return;
      }
      try
      {
        await axios.post(this.apiUrl + '/addupdbalsena', indx, {
          headers: { "Authorization": 'Bearer ' + this.storage.token }
        }).then(async (result)  => {
          alert("Success Saved User - " + result.data.result)
        })
      }
      catch (error)
      {
        await axios.post(this.apiUrl + '/refresh', {}, {
          headers: { "Authorization": 'Bearer ' + this.storage.refresh_token }
        }).then(async (result)  => {
          this.storage.token = result.data.token
          this.storage.refresh_token = result.data.refresh_token
          localStorage.setItem('user', JSON.stringify(this.storage));
          this.$forceUpdate();
        })
      }
    },
    async onBalSenaDelete(event)
    {
      console.log(event)
      let indx = event.data
      if (this.storage.role == 0 || this.storage.role == 1)
      {
        alert("Not Authorized!");
        return;
      }
      let result = confirm("<b>Are you sure?</b>", "Confirm Delete");
      result.then((dialogResult) => {
        if (dialogResult)
        {
          axios.post(this.apiUrl + '/delete/BalSena_Form', {_id:indx._id}, {
            headers: { "Authorization": 'Bearer ' + this.storage.token }
          }).then(async (result)  => {
            alert("Success Deleted - " + result.data.result)
          })
        }
      });
    },
  },
  data() {
    return {
      lang: {},
      volunteers: [],
      areas: [],
      allbalsena: [],
      storage: Object,
      genders: [{ ID: "M", Name: "Male" }, { ID: "F", Name: "Female" }],
      school_type: [{ ID: "G", Name: "Govt" }, { ID: "P", Name: "Private" }],
      pageSizes: [5, 10, 'all'],
    };
  },
};
</script>
