<template>
  <div class="about">
    <img alt="Vue logo" src="../assets/logo.png">
    
    <Pages msg="Welcome to Shaishav's Dashboard App"/>
  </div>
</template>
<script>
// @ is an alias to /src
import Pages from '@/components/Pages.vue'

export default {
  name: "About",
  components: {
    Pages
  },
  data() {
    return {
    };
  },
};
</script>