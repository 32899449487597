<template>
    <div class="login">
  <DxPopup v-model:visible="display" :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '50vw'}">

    <h3>Select User to be Logged In</h3>

    <DxDataGrid :data-source="users" v-model:selection="user">
      <DxColumn data-field="_id" header="ID"></DxColumn>
      <DxColumn data-field="name" header="Name"></DxColumn>
      <DxColumn data-field="email" header="Email"></DxColumn>
      <DxColumn data-field="phone" header="Phone"></DxColumn>
    </DxDataGrid>

    <DxButton text="No" icon="times" class="p-button-text" @click="display=false" />
    <DxButton text="Yes" icon="check" autofocus @click="selUser()" />

  </DxPopup>

  <img src="..//assets/logo.png"/> <br/>
    <h1>CHDHC Contact Management Tool App - Login</h1>
    User Name (Email): <DxTextBox v-model="username" placeholder="username" />
    <br />
    <br />
    Password: <DxTextBox v-model="password" placeholder="password" mode="password" />
    <br />
    <br />
    <DxButton type="normal" @click="loginBtn" text="Login" /> 
    <DxButton type="normal" @click="handleClickSignIn" text="Google Signin" />
    <DxButton type="normal" @click="handleClickSignOut" text="Signout" />
  </div>
</template>
<script>

import { inject, toRefs } from "vue";

export default 
{
  name: 'Login',
  setup(props)
  {
    const { isSignIn } = toRefs(props);
    const Vue3GoogleOauth = inject("Vue3GoogleOauth");
    const handleClickLogin = () => {};
    const storage = localStorage.getItem('user');

    return {
      Vue3GoogleOauth,
      handleClickLogin,
      isSignIn,
      storage,
    };
  },
  data: () => {
    return {
      user: {},
      users: [],
      username:'',
      password: '',
      display: false,
      gsignin: false,
    };
  },
  methods: {
    setLoggedInUser(user)
    {
      console.log(user)
      delete user['password']

      this.storage.setStorage({
        key: "user",
        data: user,
        success: () => {
          alert("Logged In!")
        }
      })
      this.user = user;
      this.$router.push("/");
    },
    selUser()
    {
      if(Object.keys(this.user).length === 0)
      {
        alert("Select a user to Login!")
      }
      else
      {
        this.setLoggedInUser(this.user);
        this.display = false;
      }
    },
    async handleClickSignIn()
    {
      try
      {
        const googleUser = await this.$gAuth.signIn();
        if (!googleUser)
        {
          return null;
        }
        this.user = googleUser.getBasicProfile().getEmail();
        this.login(this.user, this.Vue3GoogleOauth.isAuthorized);
        console.log("getBasicProfile", googleUser.getBasicProfile());
        console.log("isAuthorized", this.Vue3GoogleOauth.isAuthorized);
      }
      catch (error)
      {
        //on fail do something
        console.log(error);
        return null;
      }
    },
    async handleClickSignOut()
    {
      try
      {
        if (this.Vue3GoogleOauth.isAuthorized)
          await this.$gAuth.signOut();
        console.log("isAuthorized", this.Vue3GoogleOauth.isAuthorized);
        this.user = "";
      }
      catch (error)
      {
        console.error(error);
      }
      if (this.storage != undefined)
      {
        localStorage.clear();
      }
    },
    async login(email, gsignin)
    {
      localStorage.clear();
      let pwd = this.password
      if (this.password == '') pwd = 'test'
      const response = await fetch(this.apiUrl + "login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          password: pwd,
          email: email,
          gsignin: gsignin
        }),
      });
      const user = await response.json();
      if (user['token'])
      {
        await localStorage.setItem('user', JSON.stringify(user));
        alert("Logged In!")
        this.$router.push("/");
      }
      else
      {
        alert("Could not Login! " + user)
      }
    },
    loginBtn()
    {
      this.login(this.username, false);
    },
  },
};
</script>
<style scoped>
.login {
  max-width: 1024px;
  display: block;
  margin: auto;
}
</style>
