import { createApp } from 'vue'
import App from './App.vue'

import router from './router'
import 'devextreme/dist/css/dx.light.css';

import { DxNumberBox } from 'devextreme-vue/number-box';
import DxMenu from 'devextreme-vue/menu';
import DxButton from 'devextreme-vue/button';
import DxHtmlEditor, {
  DxItem as DxHTMLItem,
  DxToolbar,
  DxMediaResizing,
  DxImageUpload,
} from 'devextreme-vue/html-editor';
import DxTextBox from 'devextreme-vue/text-box';
import MyPopup from 'devextreme-vue/popup';
import DxDateBox from 'devextreme-vue/date-box';
import DxLookup from 'devextreme-vue/lookup'
import DxDataGrid, {
  DxLookup as DxDGLookup, 
  DxToolbar as DxDGToolBar,
  DxItem as DxDGItem, 
  DxColumn, DxPager, 
  DxLoadPanel, DxColumnChooser,
  DxEditing, DxPaging, DxPopup, DxFilterRow, DxSearchPanel, DxHeaderFilter, DxExport, DxSelection
} from 'devextreme-vue/data-grid';
//import { confirm as ConfirmDlg } from 'devextreme/ui/dialog';

import DxForm, {
  DxItem as DxFormItem, 
  DxSimpleItem,
  DxGroupItem,
  DxButtonItem,
  DxLabel,
} from 'devextreme-vue/form';
import DxTagBox from "devextreme-vue/tag-box";
import { DxBox, DxItem as DxBoxItem } from 'devextreme-vue/box';
import DxScrollView from 'devextreme-vue/scroll-view';
import Vue3Storage, { StorageType } from "vue3-storage";
import GAuth from 'vue3-google-oauth2'

const gAuthOptions = { clientId: '908421253481-ti3k1mp9letdk7ss37tum4m4qnsvop0h.apps.googleusercontent.com', 
    scope: 'email profile', prompt: 'consent', fetch_basic_profile: false }

const app = createApp(App);
app.use(GAuth, gAuthOptions)

app.use(Vue3Storage, { namespace: "pro_", storage: StorageType.Local });

app.use(router);
app.component('DxFormItem', DxFormItem);
app.component('DxLoadPanel', DxLoadPanel);
app.component('DxColumnChooser', DxColumnChooser);
app.component('DxDGToolBar', DxDGToolBar);
app.component('DxSimpleItem', DxSimpleItem);
app.component('DxGroupItem', DxGroupItem);
app.component('DxButtonItem', DxButtonItem);
app.component('DxLabel', DxLabel);
app.component('DxPager', DxPager);
app.component('DxPaging', DxPaging);
app.component('DxNumberBox', DxNumberBox);
app.component('DxMenu', DxMenu);
app.component('DxLookup', DxLookup);
app.component('DxBox', DxBox);
app.component('DxBoxItem', DxBoxItem);
app.component('DxDateBox', DxDateBox);
app.component('DxButton', DxButton);
app.component('DxHtmlEditor', DxHtmlEditor);
app.component('DxToolbar', DxToolbar);
app.component('DxMediaResizing', DxMediaResizing);
app.component('DxImageUpload', DxImageUpload);
app.component('DxHTMLItem', DxHTMLItem);
app.component('MyPopup', MyPopup);
app.component('DxTextBox', DxTextBox);
app.component('DxDataGrid', DxDataGrid);
app.component('DxColumn', DxColumn);
app.component('DxDGLookup', DxDGLookup);
app.component('DxDGItem', DxDGItem);
app.component('DxEditing', DxEditing);

app.component('DxPopup', DxPopup);
app.component('DxFilterRow', DxFilterRow);
app.component('DxSearchPanel', DxSearchPanel);
app.component('DxHeaderFilter', DxHeaderFilter);
app.component('DxExport', DxExport);
app.component('DxSelection', DxSelection);
app.component('DxForm', DxForm);
app.component('DxTagBox', DxTagBox);
app.component('DxScrollView', DxScrollView);

app.mount("#app");
app.config.globalProperties.apiUrl = "https://api.shaishav.org/"
