import { createRouter, createWebHistory } from 'vue-router'
import Volunteers from '../views/Volunteers.vue'
import Villages from '../views/Villages.vue'
import Areas from '../views/Areas.vue'
import Dashboard from '../views/Dashboard.vue'
import About from '../views/About.vue'
import Pages from '../components/Pages.vue'
import AranyakSurvey from '../views/AranyakSurvey.vue'
import BalSenaForm from '../views/BalSenaForm.vue'
import Login from '../views/Login.vue'
import AranyakAttendence from '../views/AranyakAttendence.vue'
import BalsenaAttendence from '../views/BalsenaAttendence.vue'

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/volunteers',
    name: 'Volunteers',
    component: Volunteers
  },
  {
    path: '/villages',
    name: 'Villages',
    component: Villages
  },
  {
    path: '/areas',
    name: 'Areas',
    component: Areas
  },
  {
    path: '/aranyaksurvey',
    name: 'AranyakSurvey',
    component: AranyakSurvey
  },
  {
    path: '/balsena',
    name: 'BalSenaForm',
    component: BalSenaForm
  },
  {
    path: '/aranyakattendence',
    name: 'aranyakattendence',
    component: AranyakAttendence
  },
  {
    path: '/balsenaattendence',
    name: 'balsenaattendence',
    component: BalsenaAttendence
  },
  {
    path: '/about',
    name: 'About',
    component: About
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited. /* webpackChunkName: "about" */ 
    //component: () => import('../views/About.vue')
  },
  {
    path: '/pages:id',
    name: 'Pages',
    component: {Pages},
  },
  // otherwise redirect to Dashboard
  {
    path: '/:pathMatch(.*)*', 
    name: 'not-found',
    component: Dashboard
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

router.beforeEach((to, from, next) => {
  const storage = JSON.parse(localStorage.getItem('user'));
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/login'];
  const authRequired = !publicPages.includes(to.path);
  let loggedIn = false;
  if (storage) loggedIn = true;

  if (authRequired && !loggedIn) {
    return next({ 
      path: '/login', 
      query: { returnUrl: to.path } 
    });
  }
  next();
})
