<template>
  <div class="dashboard">
    <img src="../assets/logo.png"/> <br/>
    <h2> Shaishav CRM and Data App Profile - {{profile[0]?.name}}</h2>

    <DxDataGrid v-model:data-source="profile" key-expr="_id" @saved="onProfileSave($event)" @editing-start="editEvent" >
      <DxEditing :allow-updating="true" mode="popup">
        <DxPopup :show-title="true" title="Edit Profile" />
        <DxDGItem :col-count="1" :col-span="2" item-type="group" caption="name_full_details">
          <DxDGItem data-field="_id" />
          <DxDGItem data-field="name" />
          <DxDGItem data-field="email" />
          <DxDGItem data-field="password" />
          <DxDGItem data-field="Phone" />
          <DxDGItem data-field="Language" />
          <DxDGItem data-field="Work" />
          <DxDGItem data-field="role" />
        </DxDGItem>
      </DxEditing>
      <DxColumn caption="ID" data-field="_id" />
      <DxColumn caption="Full Name" data-field="name" />
      <DxColumn caption="Email" data-field="email" />
      <DxColumn :visible="false" data-field="password" edit-cell-template="passTemplate" />
      <DxColumn caption="Phone" data-field="Phone" />
      <DxColumn caption="Language" data-field="Language" >
        <DxDGLookup
          :data-source="languages"
          value-expr="ID"
          display-expr="Name"
        />
      </DxColumn>
      <DxColumn caption="Work" data-field="Work">
        <DxDGLookup
          :data-source="works"
          value-expr="ID"
          display-expr="Name"
        />
      </DxColumn>
      <DxColumn :visible="false" data-field="role">
        <DxDGLookup
          :data-source="roles"
          value-expr="ID"
          display-expr="Name"
        />
      </DxColumn>
      <template #passTemplate >
        <DxTextBox v-model:value="password" mode="password" />
      </template>
    </DxDataGrid>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Dashboard",
  async created () 
  {
    this.storage = await JSON.parse(localStorage.getItem('user'));
    try
    {
      await axios.post(this.apiUrl + '/getuser', {email: this.storage.email}, {
        headers: { "Authorization": 'Bearer ' + this.storage.token }
      }).then(result => {
        this.profile = []
        this.profile.push(result.data)
      })
    }
    catch (error)
    {
      await axios.post(this.apiUrl + '/refresh', {}, {
        headers: { "Authorization": 'Bearer ' + this.storage.refresh_token }
      }).then(result  => {
        this.storage.token = result.data.token
        this.storage.refresh_token = result.data.refresh_token
        localStorage.setItem('user', JSON.stringify(this.storage));
        this.$forceUpdate();
      })
    }
  },
  methods:
  {
    editEvent(event)
    {
      this.user = event.data;
      this.password = ''
    },
    async onProfileSave(event)
    {
      if (event.changes.length <= 0 && this.password == "")
      {
        alert("No Data Changed to be Saved!")
        return;
      }
      var md5 = require('md5');
      if (event.changes.length > 0)
      {
        this.user = event.changes[0].data
      }
      if (this.password != "")
      {
        this.user.password = md5(this.password)
      }
      try
      {
        await axios.post(this.apiUrl + '/addupduser', this.user, {
          headers: { "Authorization": 'Bearer ' + this.storage.token }
        }).then(result => {
          this.storage.Language = this.user.Language
          localStorage.setItem('user', JSON.stringify(this.storage));
          alert("Success Saved User - " + result.data.result)
        })
      }
      catch (error)
      {
        await axios.post(this.apiUrl + '/refresh', {}, {
          headers: { "Authorization": 'Bearer ' + this.storage.refresh_token }
        }).then(async (result)  => {
          this.storage.token = result.data.token
          this.storage.refresh_token = result.data.refresh_token
          localStorage.setItem('user', JSON.stringify(this.storage));
          this.$forceUpdate();
        })
      }
    },
  },
  data()
  {
    return {
      profile: [],
      user:{},
      password: '',
      storage: Object,
      languages: [{ID:'en', Name: "English"}, {ID:'gj', Name:"Gujarati"}],
      works: [{ID:'a', Name: "Aranyak"}, {ID:'b', Name:"Balsena"}],
      roles: [{ID:'0', Name: "Viewer"}, {ID:'1', Name:"Editor"}, {ID:'2', Name:"Admin"}],
    };
  },
};
</script>
<style scoped>
.dashboard{
  max-width: 1024px;
  display: block;
  margin: auto;
}
</style>