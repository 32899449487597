<template>
  <div class="areas">
    <DxDataGrid :data-source="areas" :show-borders="true" @saved="onAreaSave">
      <DxFilterRow :visible="true" apply-filter="auto" />
      <DxHeaderFilter :visible="true" />
      <DxSearchPanel :visible="true" placeholder="Search..." />
      <DxExport :enabled="storage.role==2" :allow-export-selected-data="storage.role==2" />
      <DxPaging :enabled="true"/>
      <DxEditing :allow-updating="storage.role==2" :allow-adding="storage.role==2" :allow-deleting="storage.role==2" mode="popup">
        <DxPopup :show-title="true" title="Edit Area" />
        <DxForm>
          <DxDGItem :col-count="2" :col-span="2" item-type="group">
            <DxDGItem data-field="_id" />
            <DxDGItem data-field="name" />
            <DxDGItem data-field="zone" />
            <DxDGItem data-field="Notes" />
          </DxDGItem>
        </DxForm>
      </DxEditing>
      <DxColumn data-field="_id" header="ID" :width="50" />
      <DxColumn data-field="name" header="Name" />
      <DxColumn data-field="zone" header="Zone" />
      <DxColumn data-field="Notes" header="Notes" />
    </DxDataGrid>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: 'Areas',
  async created () 
  {
    this.storage = JSON.parse(localStorage.getItem('user'));
    if (this.storage.role == 0 || this.storage.role == 1) {
      alert("Not Authorized!")
      return;
    }
    try
    {
      await axios.get(this.apiUrl + '/read/Areas', {
        headers: { "Authorization": 'Bearer ' + this.storage.token }
      }).then(async (result)  => {
        this.areas = result.data
      })
    }
    catch (error)
    {
      console.log(error)
      await axios.post(this.apiUrl + '/refresh', {}, {
        headers: { "Authorization": 'Bearer ' + this.storage.refresh_token }
      }).then(async (result)  => {
        this.storage.token = result.data.token
        this.storage.refresh_token = result.data.refresh_token
        localStorage.setItem('user', JSON.stringify(this.storage));
        this.$forceUpdate();
        this.updateComponent()
      })
    }
  },
  methods:
  {
    async onAreaSave(_evemt)
    {
      if (_evemt.changes.length <= 0)
      {
        alert("Nothing to be saved!")
        return;
      }
      let area = _evemt.changes[0].data
      try
      {
        await axios.post(this.apiUrl + '/addupdarea', area, {
            headers: { "Authorization": 'Bearer ' + this.storage.token }
        }).then(async (result)  => {
            alert("Success Saved Area Entry - " + result.data.result)
        })
      }
      catch (error)
      {
        await axios.post(this.apiUrl + '/refresh', {}, {
          headers: { "Authorization": 'Bearer ' + this.storage.refresh_token }
        }).then(async (result)  => {
          this.storage.token = result.data.token
          this.storage.refresh_token = result.data.refresh_token
          localStorage.setItem('user', JSON.stringify(this.storage));
          this.$forceUpdate();
        })
      }
    },
    async onDonationDelete()
    {
      if (this.storage.role == 0 || this.storage.role == 1)
      {
        alert("Not Authorized!")
        return;
      }
    },
  },
  data() {
    return {
      storage: Object,
      areas: [],
    };
  },
}
</script>
