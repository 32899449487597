<template>
  <div class="aranyakattendence">
  <DxDataGrid :data-source="allaranyakattendence" :focused-row-enabled="true" key-expr="_id">
    <DxColumnChooser :enabled="true"/>
    <DxLoadPanel :enabled="true"/>
    <DxToolbar>
      <DxDGItem location="before" template="collapseTemplate" />
      <DxDGItem name="columnChooserButton" />
      <DxDGItem name="searchPanel" />
    </DxToolbar>
    <DxFilterRow :visible="true" apply-filter="auto" />
      <DxHeaderFilter :visible="true" />
      <DxSearchPanel :visible="true" :width="240" placeholder="Search..." />

      <DxColumn data-field="_id" />
      <DxColumn :caption="lang.RegistrationNumber" data-field="RegNo" />
      <DxColumn :caption="lang.Name" data-field="Name" />
      <DxColumn :visible="false" :caption="lang.mother" data-field="MothersName" />
      <DxColumn :visible="false" :caption="lang.father" data-field="FathersName" />
      <DxColumn :visible="false" :caption="lang.SurName" data-field="SurName" />
      <DxColumn :caption="lang.Gender" data-field="Gender">
        <DxLookup :data-source="genders" value-expr="ID" display-expr="Name" />
      </DxColumn>
      <DxColumn :caption=lang.DateOfBirth data-type="date" data-field="DateOfBirth" />
      <DxColumn :caption=lang.WenlidoTraining data-type="number" data-field="WenlidoTraining" />
      <DxColumn :caption=lang.Phone data-field="Phone" />
      <DxColumn :caption=lang.AttendingVanshalaBefore data-type="number" data-field="AttendingVanshalaBefore" />
      <DxColumn :caption=lang.AttendedCFVBefore data-type="number" data-field="AttendedCFVBefore" />
      <DxColumn :caption=lang.CurrentlyStudying data-field="CurrentlyStudying" data-type="boolean" />
      <DxColumn :caption=lang.Class data-type="number" data-field="Class" />
      <DxColumn :visible="false" :caption=lang.School data-field="School" />
      <DxColumn :visible="false" :caption=lang.private_or_govt data-field="PrivateSchool">
        <DxLookup :data-source="school_type" value-expr="ID" display-expr="Name" />
      </DxColumn>
      <DxColumn :visible="false" :caption=lang.SchoolTiming data-field="SchoolTimining" />
      <DxColumn :visible="false" :caption=lang.GoToTuition data-field="GoToTuition" />
      <DxColumn :visible="false" :caption=lang.WorkingOutside data-field="WorkingOutside" data-type="boolean" />
      <DxColumn :visible="false" :caption=lang.child_work_in_their_farms data-field="WorkWithFamily" data-type="boolean" />
      <DxColumn :visible="false" :caption=lang.ChildLabour data-field="ChildLabour" /> 
      <DxColumn :visible="false" :caption=lang.engaged data-field="CurrentlyEngagned" data-type="boolean" />
      <DxColumn :visible="false" :caption=lang.married data-field="Married" data-type="boolean" />
      <DxColumn :visible="false" :caption=lang.Addictions data-field="Addictions" /> 
      <DxColumn :visible="false" :caption=lang.disabilities data-field="Disabilities" /> 
      <DxColumn :visible="false" :caption=lang.disability_certificate data-field="CertificateOfDisability" data-type="boolean" />
      <DxColumn :visible="false" :caption=lang.sicklecell data-field="SickleCell" data-type="boolean" />
      <DxColumn :visible="false" :caption=lang.aneamic data-field="Aneamic" data-type="boolean" />
      <DxColumn :visible="false" :caption=lang.child_stay_with data-field="OrphanStayingWith" />
      <DxColumn :visible="false" :caption="lang.mother +' '+ lang.alive" data-field="MotherAlive" data-type="boolean" />
      <DxColumn :visible="false" :caption="lang.father +' '+ lang.alive" data-field="FatherAlive" data-type="boolean" />
      <DxColumn :visible="false" :caption=lang.ParentsMigrate data-field="ParentsMigrate" />
      <DxColumn :caption=lang.Facilitator data-field="Facilitator">
        <DxLookup :data-source="volunteers" value-expr="_id" display-expr="name" />
      </DxColumn>
      <DxColumn :caption=lang.Village data-field="Village">
        <DxLookup :data-source="villages" value-expr="_id" display-expr="name" />
      </DxColumn>
      <DxColumn :visible="false" :caption=lang.Notes data-field="Notes" />
      <DxPaging :page-size="10"/>
      <DxPager
        :visible="true"
        :allowed-page-sizes="pageSizes"
        display-mode="compact"
        :show-page-size-selector="true"
        :show-info="true"
        :show-navigation-buttons="true"
      />
    </DxDataGrid>
  </div>
</template>

<script>
import axios from "axios";
import enjson from "../assets/locales/en.json"
import gjjson from "../assets/locales/gj.json"
import { confirm } from 'devextreme/ui/dialog';

export default{
  name: "AranyakAttendence",
  async created()
  {
    this.storage = JSON.parse(localStorage.getItem("user"));
    if (this.storage.role == 0 || this.storage.role == 1)
    {
      alert("Not Authorized!");
      return;
    }
    if (this.storage.Language == "en")
      this.lang = enjson;
    else
      this.lang = gjjson;
    try
    {
      await axios.get(this.apiUrl + "/read/users", {
        headers: { "Authorization": "Bearer " + this.storage.token }
      }).then(async (result) => {
        this.volunteers = result.data;
      });
    }
    catch (error)
    {
      console.log(error);
      await axios.post(this.apiUrl + "/refresh", {}, {
        headers: { "Authorization": "Bearer " + this.storage.refresh_token }
      }).then(async (result) => {
        this.storage.token = result.data.token;
        this.storage.refresh_token = result.data.refresh_token;
        localStorage.setItem("user", JSON.stringify(this.storage));
        this.$forceUpdate();
        //this.updateComponent()
      });
    }
    await axios.get(this.apiUrl + "/read/Villages", {
      headers: { "Authorization": "Bearer " + this.storage.token }
    }).then(async (result) => {
      this.villages = result.data;
    });
    await axios.get(this.apiUrl + "/read/Aranyak_Survey", {
      headers: { "Authorization": "Bearer " + this.storage.token }
    }).then(async (result) => {
      this.allaranyakattendence = result.data;
    });
  },
  methods: {
    async onAranyakAttendenceSave()
    {
      if (this.storage.role == 0 || this.storage.role == 1)
      {
        alert("Not Authorized!");
        return;
      }
      if (this.selItem.RegNo == "")
      {
        alert("Registration Number Not Valid!");
        return;
      }
      if (this.selItem.Facilitator == undefined) {
        alert("Could not Save AranyakAttendence. Select a Facilitator!");
        return;
      }
      try
      {
        await axios.post(this.apiUrl + '/addupdaranyak', this.selItem, {
          headers: { "Authorization": 'Bearer ' + this.storage.token }
        }).then(async (result)  => {
          alert("Success Saved User - " + result.data.result)
        })
      }
      catch (error)
      {
        await axios.post(this.apiUrl + '/refresh', {}, {
          headers: { "Authorization": 'Bearer ' + this.storage.refresh_token }
        }).then(async (result)  => {
          this.storage.token = result.data.token
          this.storage.refresh_token = result.data.refresh_token
          localStorage.setItem('user', JSON.stringify(this.storage));
          this.$forceUpdate();
        })
      }
    },
    async onAranyakAttendenceDelete()
    {
      if (this.storage.role == 0 || this.storage.role == 1)
      {
        alert("Not Authorized!");
        return;
      }
      let result = confirm("<b>Are you sure?</b>", "Confirm Delete");
      result.then((dialogResult) => {
        if (dialogResult)
        {
          axios.post(this.apiUrl + '/delete/Aranyak_Survey', {_id:this.selItem._id}, {
            headers: { "Authorization": 'Bearer ' + this.storage.token }
          }).then(async (result)  => {
            alert("Success Deleted - " + result.data.result)
            this.formPopup = false
          })
        }
      });
    },
  },
  data()
  {
    return {
      lang: {},
      selItem: {},
      formPopup: false,
      volunteers: [],
      villages: [],
      allaranyakattendence: [],
      storage: Object,
      genders: [{ ID: "M", Name: "Male" }, { ID: "F", Name: "Female" }],
      school_type: [{ ID: "G", Name: "Govt" }, { ID: "P", Name: "Private" }],
      pageSizes: [5, 10, 'all'],
    };
  },
};
</script>
