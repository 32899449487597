<template>
  <div>
    <DxMenu :data-source="items" 
      show-first-submenu-mode="true"
      orientation="horizontal"
      display-expr="label"
      @item-click="itemClick" />
    <router-view/>
  </div>
</template>
<script>

export default
{
   created ()
   {
      document.title = "Shaishav CRM and Data App!";
   },
   methods:{
      itemClick(e) {
         if (e.itemData['to'])
            this.$router.push(e.itemData['to']);
      },
   },
   data() {
      return {
         storage: Object,
         items: [{
            label:'Dashboard',
            icon:'home',
            to: "/"
         },
         {
            label:'File',
            icon:'file',
            items:[
               {
                  label:'Aranyak Attendence',
                  icon:'link',
                  to: "/aranyakattendence"
               },
               {
                  label:'Balsena Attendence',
                  icon:'link',
                  to: "/balsenaattendence"
               }
            ]
         },
         {
            label:'Edit',
            icon:'edit',
            items:[
               {
                  label:'Volunteers',
                  icon:'aligncenter',
                  to: "/volunteers"
               },
               {
                  label:'Villages',
                  icon:'aligncenter',
                  to: "/villages"
               },
               {
                  label:'Areas and Zones',
                  icon:'aligncenter',
                  to: "/areas"
               },
               {
                  label:'Aranyak Survey',
                  icon:'alignleft',
                  to: "/aranyaksurvey"
               },
               {
                  label:'BalSena Form',
                  icon:'alignleft',
                  to: "/balsena"
               },
            ]
         },
         {
            label:'About',
            icon:'alignjustify',
            to: "/about"
         },
         {
            label:"Login",
            icon:'user',
            to: "/login"
         }]
      }
   }
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
