<template>
  <div class="balsenaattendence">
    <h1>{{lang.Attendence}}</h1>
    <div class="field grid">
      Date of {{lang.Attendence}}: <DxDateBox v-model="today" dateFormat="yy-mm-dd" @date-select="onArea()" />
      <div class="col">
        Area: <DxSelectBox :items="areas" :value="areas" @value-changed="onArea()" />
      </div>
      <div class="col">
        Zone: <DxSelectBox :value="zone" :items="areas" @value-changed="onArea()" />
      </div>
    </div>
    <DxPopup v-model:visible="displayModal"
    :hide-on-outside-click="true"
    :show-close-button="false"
    :show-title="true" title="Edit Balsena Survey Add/Update">
    <DxScrollView>
      <strong>Balsena {{lang.Attendence}} - {{selbalsenasurvey.sno}} <br /> {{lang.aranyak_form_subtitle}} {{lang.survey_date}} {{formatDate(selbalsenasurvey.created_at, 'DD MMM YYYY')}}</strong>

    <div class="field grid">
      <label class="col-fixed" style="width:250px">{{lang.mother}}</label>
      <div class="col">
        {{selbalsenasurvey.MothersName}}
      </div>
    </div>
    <div class="field grid">
      <label class="col-fixed" style="width:250px">{{lang.father}}</label>
      <div class="col">
        {{selbalsenasurvey.FathersName}}
      </div>
    </div>
    <div class="field grid">
      <label class="col-fixed" style="width:250px">{{lang.SurName}}</label>
      <div class="col">
        {{selbalsenasurvey.SurName}}
      </div>
    </div>
    <div class="field grid">
      <label class="col-fixed" style="width:250px">{{lang.Gender}}</label>
      <div class="col">
        {{selbalsenasurvey.Gender}}
      </div>
    </div>
    <div class="field grid">
      <label class="col-fixed" style="width:250px">{{lang.DateOfBirth}}</label>
      <div class="col">
        {{selbalsenasurvey.DateOfBirth}}
      </div>
    </div>
    <div class="field grid">
      <label class="col-fixed" style="width:250px">{{lang.WenlidoTraining}}</label>
      {{selbalsenasurvey.WenlidoTraining}}
    </div>
    <div class="field grid">
      <label class="col-fixed" style="width:250px">{{lang.Phone}}</label>
      <div class="col">
        {{selbalsenasurvey.Phone}}
      </div>
    </div>
    <div class="field grid">
      <label class="col-fixed" style="width:250px">{{lang.AttendedVanshalaBefore}}</label>
      {{selbalsenasurvey.AttendingVanshalaBefore}}
    </div>
    <div class="field grid">
      <label class="col-fixed" style="width:250px">{{lang.Class}}</label>
      <div class="col">
        {{selbalsenasurvey.Class}}
      </div>
    </div>
    <div class="field grid">
      <label class="col-fixed" style="width:250px">{{lang.School}}</label>
      <div class="col">
        {{selbalsenasurvey.School}}
      </div>
    </div>
    <div class="field grid">
      <label class="col-fixed" style="width:250px">{{lang.private_or_govt}}</label>
      {{selbalsenasurvey.PrivateSchool}}
    </div>
    <div class="field grid">
      <label class="col-fixed" style="width:250px">{{lang.SchoolTimining}}</label>
      <div class="col">
        {{selbalsenasurvey.SchoolTimining}}
      </div>
    </div>
    <div class="field grid">
      <label class="col-fixed" style="width:250px">{{lang.GoToTuition}}</label>
      <div class="col">
        {{selbalsenasurvey.GoToTuition}}
      </div>
    </div>
    <div class="field grid">
      <label class="col-fixed" style="width:250px">{{lang.WorkingOutside}}</label>
      {{selbalsenasurvey.WorkingOutside}}
    </div>
    <div class="field grid">
      <label class="col-fixed" style="width:250px">{{lang.CurrentlyStudying}}</label>
      {{selbalsenasurvey.CurrentlyStudying}}
    </div>
    <div class="field grid">
      <label class="col-fixed" style="width:250px">{{lang.child_work_in_their_farms}}</label>
      {{selbalsenasurvey.WorkWithFamily}}
    </div>
    <div class="field grid">
      <label class="col-fixed" style="width:250px">{{lang.ChildLabour}}</label>
      {{selbalsenasurvey.ChildLabour}}
    </div>
    <div class="field grid">
      <label class="col-fixed" style="width:250px">{{lang.engaged}}</label>
      {{selbalsenasurvey.CurrentlyEngagned}}
    </div>
    <div class="field grid">
      <label class="col-fixed" style="width:250px">{{lang.married}}</label>
      {{selbalsenasurvey.Married}}
    </div>
    <div class="field grid">
      <label class="col-fixed" style="width:250px">{{lang.Addictions}}</label>
      <div class="col">
        {{selbalsenasurvey.Addictions}}
      </div>
    </div>
    <div class="field grid">
      <label class="col-fixed" style="width:250px">{{lang.disabilities}}</label>
      <div class="col">
        {{selbalsenasurvey.Disabilities}}
      </div>
    </div>
    <div class="field grid">
      <label class="col-fixed" style="width:250px">{{lang.disability_certificate}}</label>
      {{selbalsenasurvey.CertificateOfDisability}}
    </div>
    <div class="field grid">
      <label class="col-fixed" style="width:250px">{{lang.sicklecell}}</label>
      {{selbalsenasurvey.SickleCell}}
    </div>
    <div class="field grid">
      <label class="col-fixed" style="width:250px">{{lang.aneamic}}</label>
      {{selbalsenasurvey.Aneamic}}
    </div>
    <div class="field grid">
      <label class="col-fixed" style="width:250px">{{lang.child_stay_with}}</label>
      <div class="col">
        {{selbalsenasurvey.OrphanStayingWith}}
      </div>
    </div>
    <div class="field grid">
      <label class="col-fixed" style="width:250px">{{lang.mother}} {{lang.alive}}</label>
      {{selbalsenasurvey.MotherAlive}}
    </div>
    <div class="field grid">
      <label class="col-fixed" style="width:250px">{{lang.father}} {{lang.alive}}</label>
      {{selbalsenasurvey.FatherAlive}}
    </div>
    <div class="field grid">
      <label class="col-fixed" style="width:250px">{{lang.ParentsMigrate}}</label>
      {{selbalsenasurvey.ParentsMigrate}}
    </div>
    <div class="field grid">
      <label class="col-fixed" style="width:250px">{{lang.Facilitator}}</label>
      <div class="col">
        {{selbalsenasurvey.Facilitator}}
      </div>
    </div>
    <div class="field grid">
      <label class="col-fixed" style="width:250px">{{lang.Notes}}</label>
      <div class="col">
        {{selbalsenasurvey.Notes}}
      </div>
    </div>

      <DxButton icon="times" @click="displayModal=false" text="Close"></DxButton>
  </DxScrollView>
  </DxPopup>
  <DxDataGrid :data-source="allbalsenasurvey" >
    <DxEditing :allow-updating="true" :allow-adding="true" :allow-deleting="true" mode="popup" >
        <DxPopup :show-title="true" :width="700" :height="525" title="Edit AranyakSurvey Add/Update" />
        <DxForm>
          <DxItem :col-count="2" :col-span="2" item-type="group">
            <DxItem data-field="RegNo"/>
            <DxItem data-field="Name"/>
            <DxItem data-field="MothersName"/>
            <DxItem data-field="FathersName"/>
            <DxItem data-field="SurName"/>
            <DxItem data-field="Gender"/>
          </DxItem>
          <DxItem :col-count="2" :col-span="2" item-type="group" caption="Home Address">
            <DxItem data-field="DateOfBirth"/>
            <DxItem data-field="WenlidoTraining"/>
            <DxItem data-field="AttendedCFVBefore"/>
            <DxItem data-field="Class"/>
            <DxItem data-field="School"/>
            <DxItem data-field="Village"/>
          </DxItem>
        </DxForm>
      </DxEditing>
    <DxColumn data-field="sno" :header="lang.sno"></DxColumn>
    <DxColumn data-field="RegNo" :header="lang.RegistrationNumber"></DxColumn>
    <DxColumn data-field="Name" :header="lang.name_full_details"></DxColumn>
    <DxColumn data-field="SurName" :header="lang.SurName"></DxColumn>
    <DxColumn data-field="DateOfBirth" :header="lang.DateOfBirth"></DxColumn>
    <DxColumn data-field="WenlidoTraining" :header="lang.WenlidoTraining"></DxColumn>
    <DxColumn data-field="AttendingVanshalaBefore" :header="lang.AttendedVanshalaBefore"></DxColumn>
    <DxColumn data-field="Class" :header="lang.Class"></DxColumn>
    <DxColumn data-field="School" :header="lang.School"></DxColumn>
    <DxColumn data-field="Present" :header="lang.Present"></DxColumn>
  </DxDataGrid>
  <DxButton icon="floppy" @click="onAttendenceSave()" text="Save"></DxButton>&nbsp;
  <DxButton icon="link" text="Export" @click="exportCSV()"></DxButton>
</div>
</template>

<script>
import { useStorage } from "vue3-storage";
import moment from 'moment';
import enjson from "../assets/locales/en.json"
import gjjson from "../assets/locales/gj.json"
import DxButton from 'devextreme-vue/button';
import {
  DxDataGrid,
  DxColumn,
  DxEditing,
  DxPopup,
  DxForm,
} from 'devextreme-vue/data-grid';
//import DxTextBox from 'devextreme-vue/text-box';
//import DxSelectBox from 'devextreme-vue/select-box';
import DxDateBox from 'devextreme-vue/calendar';
//import DxCheckBox from 'devextreme-vue/check-box';
//import DxTextArea from 'devextreme-vue/text-area';
//import DxTagBox from 'devextreme-vue/tag-box';

import { DxScrollView } from 'devextreme-vue/scroll-view';

export default {
  name: "BalssenaForm",
  components:{
    DxButton, DxDataGrid,
    DxColumn,
    DxEditing,
    DxForm, DxPopup, DxDateBox, 
    //DxTextBox, DxSelectBox, DxCheckBox, DxTextArea, DxTagBox, 
    DxScrollView
  },
  async created () 
  {
    if (this.storage.role == 0 || this.storage.role == 1) {
      alert("Not Authorized!")
      return;
    }
    this.storage = useStorage("user");
    this.storage
    .getStorage({
      key: "user",
      success: (result) => {
        this.user = result.data
      },
      fail: res => {
        console.log("------", res);
      }
    })
    .catch(reason => {
      console.log("reason------", reason);
    });
    const response2 = await fetch(this.apiUrl + "dash_api.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fun: "getallareas",
      }),
    });
    //this.areas = await response2.json();
    let ars = await response2.json();
    ars.forEach(element => {
      this.areas.push(element.name)
    });

    if (this.user.Language == "en") this.lang = enjson
    else this.lang = gjjson

    this.today = new Date()
    this.loading1 = false;
  },
  mounted() 
  {
    this.loading1 = true;
    this.displayModal = false;
  },
  methods:
  {
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    async onAttendenceSave()
    {
      if (this.allbalsenasurvey.length <= 0) 
      {
        alert("No Data to Save!");
        return;
      }
      await fetch(this.apiUrl + "dash_api.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          fun: "save_attendence_for_class_balsena",
          datas: this.allbalsenasurvey,
          today: this.formatDate(this.today, 'yyyy-MM-DD')
        }),
      });
    },
    async onArea()
    {
      let response1 = await fetch(this.apiUrl + "dash_api.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          "fun": "get_attemdemce_for_day_balsena",
          "day": this.formatDate(this.today, 'yyyy-MM-DD')
        }),
      });
      let presents = await response1.json();
      let presentsobj = {}
      presents.forEach(element => {
        presentsobj[element['student_id']] = +element['present'];
      });
      console.log(this.area)
      response1 = await fetch(this.apiUrl + "dash_api.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          "fun": "get_balsenasurvey_for_area",
          "area": this.area,
          "zone": this.zone,
          "date": this.formatDate(this.today, 'yyyy-MM-DD')
        }),
      });
      this.allbalsenasurvey = await response1.json();

      this.allbalsenasurvey.forEach(element => {
        if (presentsobj[+element['sno']] == undefined)
        {
          element['present'] = false;
        }
        else
        {
          element['present'] = presentsobj[+element['sno']] > 0;
        }
      });
    },
    formatDate(value, fmt)
    {
      if (value)
      {
        return moment(String(value)).format(fmt)
      }
    },
    async showModalDialog(data, isNew) 
    {
      if (this.storage.role == 0 || this.storage.role == 1) {
        alert("Not Authorized!")
        return;
      }
      this.displayModal = true;
      this.bIsNew = isNew;
      data.WenlidoTraining = (data.WenlidoTraining == '0') ? false: true
      data.AttendingVanshalaBefore = (data.AttendingVanshalaBefore == '0') ? false: true
      data.PrivateSchool = (data.PrivateSchool == '0') ? false: true
      data.WorkingOutside = (data.WorkingOutside == '0') ? false: true
      data.CurrentlyStudying = (data.CurrentlyStudying == '0') ? false: true
      data.WorkWithFamily = (data.WorkWithFamily == '0') ? false: true
      data.ChildLabour = (data.ChildLabour == '0') ? false: true
      data.CurrentlyEngagned = (data.CurrentlyEngagned == '0') ? false: true
      data.Married = (data.Married == '0') ? false: true
      data.CertificateOfDisability = (data.CertificateOfDisability == '0') ? false: true
      data.SickleCell = (data.SickleCell == '0') ? false: true
      data.Aneamic = (data.Aneamic == '0') ? false: true
      data.MotherAlive = (data.MotherAlive == '0') ? false: true
      data.FatherAlive = (data.FatherAlive == '0') ? false: true

      if (data.Addictions !== "") {
        this.chkAddictions = true
      }
      else {
        this.chkAddictions = false
      }
      if (data.Disabilities !== "") {
        this.chkDisabilities = true
      }
      else {
        this.chkDisabilities = false
      }
      this.selbalsenasurvey = {};
      if (!isNew)
      {
        this.selbalsenasurvey = data;
      }
    },
  },
  data() {
    return {
      lang: {},
      areas: [],
      area: {},
      zone: {},
      today: undefined,
      classno: 6,
      loading1: false,
      allbalsenasurvey: [],
      selbalsenasurvey: {},
      displayModal: false,
      storage: Object,
      user: {},
    };
  },
};
</script>
<style scoped>

</style>
